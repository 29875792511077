import { initializeApp } from "firebase/app";

export default initializeApp({
  apiKey: "AIzaSyB-6kdW-QnITubSGo2hJ993uESNqLAb5GE",
  authDomain: "augmented-pager-332815.firebaseapp.com",
  projectId: "augmented-pager-332815",
  storageBucket: "augmented-pager-332815.appspot.com",
  messagingSenderId: "962971556249",
  appId: "1:962971556249:web:aa1e45d3eaa59bb0aa1b08",
  measurementId: "G-H33WCFFRZR",
});

export function dedup(arr) {
  const seen = {};
  const deduped = [];
  for (const e of arr) {
    if (seen[e]) continue;
    deduped.push(e);
    seen[e] = true;
  }
  return deduped;
}

const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export function dayOfWeek(date) {
  return DAYS_OF_WEEK[date.getDay()];
}

const HOME_COOKING_ID = "__home_cooking";
export function isHomeCookingType(type) {
  return type === HOME_COOKING_ID;
}
export const AVAILABLE_TYPES = [
  { name: "Restaurant", id: "restaurant", exampleKeyword: "Mexican" },
  { name: "Home Cooking", id: HOME_COOKING_ID },
  { name: "Bar", id: "bar", exampleKeyword: "Cocktails" },
  { name: "Bakery", id: "bakery", exampleKeyword: "Croissants" },
  { name: "Cafe", id: "cafe", exampleKeyword: "Independently Owned" },
];
export function lookupTypeName(id) {
  for (const type of AVAILABLE_TYPES) {
    if (type.id === id) return type.name;
  }
  return id;
}

<script>
import { dayOfWeek, lookupTypeName } from './utils.js';

import { link } from 'svelte-routing';

export let session;

$: typeDescription = (() => {
  const typeName = lookupTypeName(session.type);
  const keyword = session.params.keyword;
  let description = '';
  if (keyword) description += keyword + ' ';
  description += typeName;
  return description;
})();

$: weekday = dayOfWeek(session.timestamp.toDate());

function friendlyTime(date) {
  const MINUTE = 60, HOUR = MINUTE * 60, DAY = HOUR * 24;

  const deltaSeconds = Math.round((+new Date() - date) / 1000);

  if (deltaSeconds < 2 * MINUTE) {
      return 'a minute ago'
  } else if (deltaSeconds < HOUR) {
      return Math.floor(deltaSeconds / MINUTE) + ' minutes ago';
  } else if (Math.floor(deltaSeconds / HOUR) == 1) {
      return '1 hour ago'
  } else if (deltaSeconds < DAY) {
      return Math.floor(deltaSeconds / HOUR) + ' hours ago';
  } else {
    return "on " + date.toISOString().slice(0,10);
  }
}
$: timeStr = friendlyTime(session.timestamp.toDate());

$: creator = {
  "aidan.oldershaw@gmail.com": "Aidan",
  "rebeccagwakefield@gmail.com": "Becca"
}[session.owner] || session.owner;
</script>

<a class="session" href="/sessions/{session.id}" use:link>
  <b>{typeDescription}</b> on {weekday}
  <div class="small">Created by {creator} {timeStr}</div>
</a>

<style>
  .session {
    display: block;
    color: #333;
    text-decoration: none;
    padding: 8px;
    margin: 4px 0;
    background-color: #ddd;
    border-radius: 4px;
  }

  .small {
    color: #888;
    font-size: 0.75em;
  }
</style>

import { writable } from "svelte/store";

// Seems backward, but the prod api key is locked down to only sf.aidan.rocks
// and must be exposed, but the dev key can't be that restrictive (and so must
// not be exposed).
//
// Note that DEV_API_KEY is interpolated into the script via rollup.config.js
export const API_KEY = DEV_API_KEY || "AIzaSyA6iMFvbbpwAnJpIK1ixggUtvCwwYWa6k4";

export const isMapsApiReady = writable(false);

const dummyElement = document.createElement("div"); // don't tell Google
let service;

function ensureService() {
  if (!service) {
    service = new google.maps.places.PlacesService(dummyElement);
  }
}
export function loadPlaces(session) {
  ensureService();
  const params = {
    keyword: session.params.keyword || undefined,
    location: {
      lat: session.params.coords.latitude,
      lng: session.params.coords.longitude,
    },
    openNow: session.params.restrictToOpenNow,
    radius: session.params.distance,
    type: session.type,
  };
  return new Promise((resolve, reject) => {
    let allPlaces = [];
    function callback(places, status, pagination) {
      if (status !== "OK") {
        reject(status);
        return;
      }
      allPlaces = allPlaces.concat(places);
      if (!pagination.hasNextPage) {
        resolve(allPlaces);
        return;
      }
      pagination.nextPage();
    }

    service.nearbySearch(params, callback);
  });
}

const placeCache = {};
export function lookupPlace(id) {
  ensureService();
  if (placeCache[id]) return Promise.resolve(placeCache[id]);
  const request = {
    placeId: id,
    fields: [
      "name",
      "formatted_address",
      "address_component",
      "photo",
      "website",
      "opening_hours",
      "geometry",
      "price_level",
      "rating",
      "user_ratings_total",
    ],
  };
  return new Promise((resolve, reject) => {
    service.getDetails(request, (place, status) => {
      if (status !== "OK") {
        reject(status);
        return;
      }
      const canonical = canonicalizePlace(id, place);
      placeCache[id] = canonical;
      resolve(canonical);
    });
  });
}

export function canonicalizePlace(id, place) {
  // {id, name, priceLevel, rating, numReviews, address, neighbourhood, website, latitude, longitude, images}
  function addressComponent(type) {
    if (!place.address_components) return "";
    for (const component of place.address_components) {
      for (const type_ of component.types) {
        if (type === type_) return component.short_name;
      }
    }
    return "";
  }
  const data = {
    id,
    name: place.name || "",
    priceLevel: "$".repeat(place.price_level || 0),
    rating: place.rating || 0,
    numReviews: place.user_ratings_total || 0,
    address: place.formatted_address || "",
    neighbourhood: addressComponent("neighborhood"),
    website: place.website || "",
    images: place.photos || [], // must be non-empty...
  };
  if (place.geometry) {
    data.latitude = place.geometry.location.lat();
    data.longitude = place.geometry.location.lng();
  } else {
    data.latitude = 0;
    data.longitude = 0;
  }
  return data;
}

export function addressLink(address) {
  return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
}

export function streetAddress(address) {
  if (!address) return "";
  const index = address.indexOf(",");
  if (index < 0) return address;
  return address.substr(0, index);
}

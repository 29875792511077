import { writable } from "svelte/store";

import firebaseApp from "./firebase.js";
import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";

export default (() => {
  const { subscribe, set } = writable({ loading: true });
  const auth = getAuth(firebaseApp);
  onAuthStateChanged(auth, (user) => {
    set({ user });
  });

  return {
    subscribe,
    login() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider).catch((error) =>
        set({ user: null, error: error })
      );
    },
    logout() {
      signOut(auth);
    },
  };
})();

let loadSidesPromise;
export function loadSides() {
  if (!loadSidesPromise) {
    loadSidesPromise = fetch("/sides.json").then((response) => response.json());
  }
  return loadSidesPromise;
}

export async function lookupSide(name) {
  const sides = await loadSidesPromise;
  return sides.find((side) => side.name === name);
}

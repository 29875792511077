export function getLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) =>
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }),
      (error) => {
        reject(error);
      }
    );
  });
}

const STRAIGHT_DISTANCE_TO_ACTUAL_FUDGE_FACTOR = 1.2;

export function approxDistanceKm(coords1, coords2) {
  const straightDistance = getDistanceFromLatLonInKm(
    parseFloat(coords1.latitude),
    parseFloat(coords1.longitude),
    parseFloat(coords2.latitude),
    parseFloat(coords2.longitude)
  );
  return straightDistance * STRAIGHT_DISTANCE_TO_ACTUAL_FUDGE_FACTOR;
}

// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

<script>
import { onMount } from 'svelte';

import { isMapsApiReady } from './maps.js';

export let coords;
export let radius; // in meters

$: latlng = {lat: coords.latitude, lng: coords.longitude};
function fromLatlng(ll) {
  return {latitude: ll.lat(), longitude: ll.lng()};
}

let container;

let hasInitialized = false;
$: {
  if ($isMapsApiReady && !hasInitialized && container != null) {
    initMap();
    hasInitialized = true;
  }
}

let map;
let marker;
let circle;
function initMap() {
  map = new google.maps.Map(container, {
    zoom: 15,
    center: latlng,
  });
  marker = new google.maps.Marker({
    position: latlng,
    map: map,
    draggable: true,
  });
  circle = new google.maps.Circle({
    map: map,
    radius: radius,
    center: latlng,
    strokeColor: "#0000FF",
    strokeOpacity: 0.35,
    strokeWeight: 2,
    fillColor: "#0000FF",
    fillOpacity: 0.20
  });
  map.fitBounds(circle.getBounds());
  google.maps.event.addListener(marker, "dragend", function(event) {
    coords = fromLatlng(marker.position);
  });
}
$: {
  if (map) {
    marker.setPosition(latlng);
    circle.setCenter(latlng);
    map.panTo(latlng);
  }
}
</script>

<div id="map" bind:this={container}></div>

<style>
  #map {
    width: 100%;
    height: 300px;
  }
</style>

import firebaseApp from "./firebase.js";

import {
  getFirestore,
  addDoc,
  getDoc,
  getDocs,
  query,
  where,
  limit,
  orderBy,
  onSnapshot,
  doc,
  collection,
  serverTimestamp,
} from "firebase/firestore";

export const RESPONSE_YES = "yes";
export const RESPONSE_NO = "no";

const db = getFirestore(firebaseApp);

export async function saveSession(session) {
  session.timestamp = serverTimestamp();
  const docRef = await addDoc(collection(db, "sessions"), session);
  return docRef.id;
}

function withId(doc) {
  const obj = doc.data();
  obj.id = doc.id;
  return obj;
}

export async function loadSession(id) {
  const d = await getDoc(doc(db, "sessions", id));
  if (!d.exists()) throw "Session not found";
  return withId(d);
}

export async function getRecentSessions(email, n) {
  let arr = [];
  const snapshot = await getDocs(
    query(
      collection(db, "sessions"),
      where("participants", "array-contains", email),
      orderBy("timestamp", "desc"),
      limit(n)
    )
  );
  snapshot.forEach((el) => arr.push(withId(el)));
  return arr;
}

export async function saveSessionResponse({
  sessionId,
  email,
  itemId,
  response,
}) {
  const docRef = await addDoc(
    collection(db, "sessions", sessionId, "responses"),
    {
      timestamp: serverTimestamp(),
      email,
      itemId,
      response,
    }
  );
}

export function listenToSessionResponses(id, handleSnapshot) {
  return onSnapshot(
    collection(db, "sessions", id, "responses"),
    handleSnapshot
  );
}

export async function loadRestaurants() {
  let arr = [];
  const snapshot = await getDocs(query(collection(db, "restaurants")));
  snapshot.forEach((el) => arr.push(el.data()));
  return arr;
}

export async function loadRestaurantByMeal(meal) {
  let arr = [];
  const snapshot = await getDocs(
    query(collection(db, "restaurants"), where("meals", "array-contains", meal))
  );
  snapshot.forEach((el) => arr.push(el.data()));
  return arr;
}

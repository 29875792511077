<script>
import auth from './auth.js';
import { saveSessionResponse, RESPONSE_YES, RESPONSE_NO } from './db.js';
import { loadSessionState } from './session.js';
import { dayOfWeek, lookupTypeName, isHomeCookingType  } from './utils.js';

import MatchesPane from './MatchesPane.svelte';
import HomeCookingCard from './HomeCookingCard.svelte';
import RestaurantCard from './RestaurantCard.svelte';
import Spinner from './Spinner.svelte';

import { onDestroy } from "svelte";
import { get } from "svelte/store";
import { fade } from "svelte/transition";
import { Link } from 'svelte-routing';

export let id;

let session;
let topItem, bottomItem, getNextItem;
$: visibleCards = [bottomItem, topItem].filter(i => i != null);

$: isHomeCooking = session && session.params && isHomeCookingType(session.params.type);

function discardTopItem() {
  topItem = bottomItem;
  bottomItem = getNextItem();
}

let location;
let unsubscribe;

let matches;
let seenMatches = {};
$: numMatches = $matches != null ? $matches.length : 0;
let newMatchForModal;
$: {
  if ($matches && $matches.length > 0) {
    const newestMatch = $matches[$matches.length - 1];
    if (!seenMatches[newestMatch]) {
      seenMatches[newestMatch] = true;
      // Only update the modal if we aren't already looking at it.
      // ...and if there's more than one participant - it's annoying if you're
      // doing a solo session.
      if (newMatchForModal == null && session.participants.length > 1) {
        newMatchForModal = newestMatch;
      }
    }
  }
}

function handleSelectMatch(event) {
  newMatchForModal = event.detail.item;
  console.log(newMatchForModal);
}

let showMatchesPane = false;
function handleClickMatchesButton() {
  if (numMatches > 0) showMatchesPane = true;
}

let loadDataPromise = loadSessionState(id, $auth.user.email)
  .then(state => {
    session = state.session;
    location = session.params.coords;
    getNextItem = state.getNextItem;
    unsubscribe = state.unsubscribe;

    for (const item of get(state.matches)) {
      seenMatches[item] = true;
    }
    matches = state.matches;

    topItem = getNextItem();
    bottomItem = getNextItem();
  })
  .catch(error => {
    console.error(error)
    throw error;
  });

onDestroy(() => {
  if (unsubscribe != null) unsubscribe();
});

// Id of the item that is active when the love/nope button is pressed.
// Propagated down into the card to animate itself.
// TODO: this is awful
let loveButtonItemId;
let nopeButtonItemId;
$: buttonsActive = !(loveButtonItemId || nopeButtonItemId);

function handleClickLoveButton() {
  if (topItem == null) return;
  loveButtonItemId = topItem;
  loveRestaurant(topItem);
}

function handleClickNopeButton() {
  if (topItem == null) return;
  nopeButtonItemId = topItem;
  nopeRestaurant(topItem);
}

function loveRestaurant(r) {
  saveSessionResponse({
    sessionId: id,
    email: $auth.user.email,
    itemId: r,
    response: RESPONSE_YES,
  }).then(() => console.log("Loved", r))
    .catch((error) => console.error("Failed to love", r.name, error));
  // Give time to animate out
  setTimeout(() => {
    discardTopItem();
    loveButtonItemId = null;
  }, 300);
}

function nopeRestaurant(r) {
  saveSessionResponse({
    sessionId: id,
    email: $auth.user.email,
    itemId: r,
    response: RESPONSE_NO,
  }).then(() => console.log("Nope'd", r))
    .catch((error) => console.error("Failed to nope", r.name, error));
  // Give time to animate out
  setTimeout(() => {
    discardTopItem();
    nopeButtonItemId = null;
  }, 300);
}

$: pageTitle = (() => {
  if (!session) return "SFinder";
  return `SFinder - ${lookupTypeName(session.type)} - ${dayOfWeek(session.timestamp.toDate())}`;
})();
</script>

<svelte:head>
  <!-- Needed to disable overscroll only for this page. -->
  <style>
    html {
      overflow: hidden;
    }
    body {
      overflow: auto;
    }
  </style>

  <title>{pageTitle}</title>
</svelte:head>

{#await loadDataPromise}
  <Spinner />
{:then}
  <div class="header">
    <Link to="/">Home</Link>
    <span>
      Looking for {lookupTypeName(session.type)}
    </span>
    <span class="matches" class:active={numMatches > 0} on:click={handleClickMatchesButton}>
      {numMatches} <span>♥</span>
    </span>
  </div>
  {#if topItem}
    <div class="cards">
      {#each visibleCards as card (card)}
        {#if isHomeCooking}
        <HomeCookingCard name={card}
                         active={card === topItem}
                         on:swiperight={() => loveRestaurant(card)}
                         on:swipeleft={() => nopeRestaurant(card)}
                         swipeLeftId={nopeButtonItemId}
                         swipeRightId={loveButtonItemId} />
        {:else}
        <!-- TODO: rename to PlaceCard -->
        <RestaurantCard placeId={card}
                        location={location}
                        active={card === topItem}
                        on:swiperight={() => loveRestaurant(card)}
                        on:swipeleft={() => nopeRestaurant(card)}
                        swipeLeftId={nopeButtonItemId}
                        swipeRightId={loveButtonItemId} />
        {/if}
      {/each}
    </div>

    <div class="buttons">
      <button on:click={handleClickNopeButton} disabled={!buttonsActive}>
        <span class="icon x"></span>
      </button>
      <button on:click={handleClickLoveButton} disabled={!buttonsActive}>
        <span class="icon heart"></span>
      </button>
    </div>
  {:else}
    <p>We've got nothing left!</p>
  {/if}

  {#if showMatchesPane}
    <MatchesPane matches={$matches}
                 location={location}
                 isHomeCooking={isHomeCooking}
                 on:select={handleSelectMatch}
                 on:hide={() => showMatchesPane = false}/>
  {/if}

  {#if newMatchForModal}
    <div class="match-modal" transition:fade>
      <h2 class="title">It's a match!</h2>
      {#if isHomeCooking}
      <HomeCookingCard name={newMatchForModal}
                       active={false} />
      {:else}
      <RestaurantCard placeId={newMatchForModal}
                      location={location}
                      active={false} />
      {/if}
      <button on:click={() => newMatchForModal = null}>Continue</button>
    </div>
  {/if}
{:catch error}
  <p class="error">Error: {error}</p>
{/await}

<style>
  .header {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5rem;
  }

  .matches {
    text-decoration: none;
    font-size: 1.3rem;
    color: #999;
    cursor: default;
  }
  .matches.active {
    cursor: pointer;
    color: #FFACE4;
  }

  .matches span {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
    font-size: 1.5rem;
  }

  .cards {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .buttons {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 24px;
    height: 60px;
    justify-content: center;
  }

  .buttons button {
    border-radius: 50%;
    width: 60px;
    border: 0;
    background: #FFFFFF;
    display: inline-block;
    margin: 0 8px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: box-shadow 150ms ease;
  }

  .buttons button:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .icon {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  }

  .icon.heart::after {
    content: '♥';
    color: #FFACE4;
    font-size: 32px;
  }

  .icon.x {
    /* 8px is arbitrary but seems to help center... */
    margin-bottom: 8px;
  }
  .icon.x::after {
    content: '×';
    color: #CDD6DD;
    font-size: 42px;
    font-weight: bold
  }

  .match-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    text-align: center;
  }

  .match-modal .title {
    font-size: 2rem;
  }

  .match-modal button {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 12px;
    margin: 0 auto;
    width: 100px;
    height: 40px;
    border-radius: 4px;
    padding: 8px 16px;
    background-color: #31599e;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-top: 8px;
  }
</style>

<script>
import { createEventDispatcher } from 'svelte';
import { fly, fade } from 'svelte/transition';

import { approxDistanceKm } from './location.js';
import { addressLink, streetAddress } from './restaurant_utils.js';
import { lookupPlace, isMapsApiReady } from './maps.js';
import { lookupSide } from './home_cooking.js';

export let matches;
export let location;
export let isHomeCooking;

// TODO: if the matches changes while calling lookupPlace, we'll end up doing
// multiple API calls - not good.
let matchItemsPromise;
$: {
  if (!isHomeCooking && $isMapsApiReady) {
    matchItemsPromise = Promise.all(matches.map(match => lookupPlace(match)));
  } else if (isHomeCooking) {
    matchItemsPromise = Promise.all(matches.map(match => lookupSide(match)));
  }
}

const dispatch = createEventDispatcher();

function roundedDistanceKm(restaurant) {
  return Math.round(approxDistanceKm(location, restaurant) * 10) / 10
}

function getBrowserWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}
</script>

<div class="behind-pane" on:click={() => dispatch("hide")} transition:fade></div>

<!-- Note: x must match the width + padding of the pane -->
<div class="pane" transition:fly={{opacity: 1, x: 332}}>
  <h3>Matches</h3>
  <div class="matches">
    {#await matchItemsPromise then matchItems}
      {#each matchItems as item}
        {#if isHomeCooking}
        <div class="match" on:click={() => dispatch("select", {item: item.name})}>
          <div class="name">{item.name}</div>
        </div>
        {:else}
        <div class="match" on:click={() => dispatch("select", {item: item.id})}>
          <div class="name">{item.name}</div>
          <div class="details">{item.priceLevel}</div>
          <div class="address">
            <a href={addressLink(item.address)}>{streetAddress(item.address)}</a>
            <span class="distance">({roundedDistanceKm(item)} km)</span>
          </div>
        </div>
        {/if}
      {/each}
    {/await}
  </div>
</div>

<style>
  .behind-pane {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .pane {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    background-color: #f1f1f1;
    padding: 0 16px;
    overflow: scroll;
  }

  .pane h3 {
    text-align: center;
  }

  .match {
    padding: 8px;
    margin-bottom: 4px;
    border-radius: 4px;
    background-color: #ddd;
    cursor: pointer;
  }

  .match .name {
    font-weight: bold;
  }

  .match .address {
    display: flex;
    align-items: center;
  }

  .match .distance {
    margin-left: 4px;
    font-size: 0.75rem;
  }

  .match .details {
    font-size: 0.75rem;
  }
</style>

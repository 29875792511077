<script>
import { fade } from 'svelte/transition';

// Either a number of an array of numbers. If array, any of the numbers are
// valid.
export let thisStage;
export let stage;

function getStageId(s) {
  // Stages are partitioned into groups of 100.
  return Math.floor(s / 100);
}

$: selectedThisStage = (() => {
  if (typeof thisStage === 'number') return thisStage;
  const index = thisStage.map(getStageId).indexOf(getStageId(stage));
  if (index < 0) return thisStage[0];
  return thisStage[index];
})();

$: active = stage === selectedThisStage;
$: visible = stage >= selectedThisStage &&
  // TODO: this is awful, and doesn't handle sub-paths.
  (selectedThisStage === 0 || getStageId(stage) === getStageId(selectedThisStage));

let element;

let prevStage = stage;
$: {
  if (active && prevStage !== selectedThisStage) {
    const prev = prevStage;
    // Must setTimeout so element is bound.
    setTimeout(() => {
      let scrollTo;
      if (prev < stage) {
        // Going forward through stages
        scrollTo = 'start';
      } else {
        // Going back to a previous stage
        scrollTo = 'end';
      }
      element.scrollIntoView({
        behavior: 'smooth',
        block: scrollTo,
      });
    }, 0);
  }
  prevStage = stage;
}

function handleClick(e) {
  if (visible && !active) {
    stage = selectedThisStage;
  }
}
</script>

{#if visible}
  <div on:click={handleClick} class="stage" class:active
       bind:this={element} transition:fade>
    <div class="wrapper"><slot /></div>
  </div>
{/if}

<style>
  .stage {
    display: block;
    padding: 0 32px 32px 32px;
    max-width: 480px;
    min-width: 300px;
    text-align: center;
    transition: opacity 0.3s ease-in-out;
  }

  .stage:not(.active) {
    opacity: 0.5;
    cursor: pointer;
  }

  :not(.active) > .wrapper {
    pointer-events: none;
  }
</style>

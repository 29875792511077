<script>
import auth from './auth.js';
import { API_KEY, isMapsApiReady } from './maps.js';

import { Router, Route } from 'svelte-routing';

import Form from './Form.svelte';
import Session from './Session.svelte';
import Spinner from './Spinner.svelte';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

window.mapsApiLoaded = function() {
  isMapsApiReady.set(true);
}
</script>

<svelte:head>
  <script defer async
    src="https://maps.googleapis.com/maps/api/js?key={API_KEY}&callback=mapsApiLoaded&libraries=places">
  </script>
</svelte:head>

{#if $auth.user}
  <Router>
    <Route path="/" component={Form}/>
    <Route path="/sessions/:id" component={Session}/>
  </Router>
{:else if $auth.loading}
  <Spinner />
{:else}
  <div class="login">
    <button on:click={() => auth.login()}>Login with Google</button>
    {#if $auth.error}
      <p class="error">Error: {$auth.error}</p>
    {/if}
  </div>
{/if}

<style>
  .login {
    text-align: center;
  }

  .error {
    color: red;
  }
</style>

<script>
import { navigate } from 'svelte-routing';
import { fade } from 'svelte/transition';

import auth from './auth.js';
import { saveSession, getRecentSessions } from './db.js';
import { getLocation } from './location.js';
import { dedup, AVAILABLE_TYPES, isHomeCookingType } from './utils.js';
import { loadPlaces } from './maps.js';

import Stage from './Stage.svelte';
import SessionCard from './SessionCard.svelte';
import Spinner from './Spinner.svelte';
import LocationPicker from './LocationPicker.svelte';

const STAGES = [
  'STAGE_PICK_TYPE',
  'STAGE_PICK_PARAMS',
  'STAGE_PICK_DISTANCE',
  'STAGE_PICK_LOCATION',
  'STAGE_PICK_PARTICIPANTS',
];
// This is ugly, but whatever - I want to get runtime errors when a stage name
// is busted, so use consts rather than the raw string.
const STAGE_PICK_TYPE = STAGES.indexOf('STAGE_PICK_TYPE');
const STAGE_PICK_PARAMS = STAGES.indexOf('STAGE_PICK_PARAMS');
const STAGE_PICK_DISTANCE = STAGES.indexOf('STAGE_PICK_DISTANCE');
const STAGE_PICK_LOCATION = STAGES.indexOf('STAGE_PICK_LOCATION');
const STAGE_PICK_PARTICIPANTS = STAGES.indexOf('STAGE_PICK_PARTICIPANTS');

let stage = 0;
$: onFirstStage = stage === 0;
let isHomeCooking = false;

let typeObj;
function selectType(t) {
  isHomeCooking = isHomeCookingType(t.id);
  typeObj = t;
  // None of the other stages are necessary for home cooking.
  if (isHomeCooking) {
    stage = STAGE_PICK_PARTICIPANTS;
  } else {
    stage++;
  }
}

let restrictToOpenNow = true;
let keyword = "";
function handleContinue() {
  stage++;
}

const AVAILABLE_DISTANCES = [
  {name: "Around the corner (5 mins)", meters: 500},
  {name: "Short Walk (10 mins)", meters: 1000},
  {name: "Medium Walk (15 mins)", meters: 1500},
  {name: "Long Walk (30 mins)", meters: 3000},
  {name: "Uber", meters: 10000},
];
let distance;
function selectDistance(d) {
  distance = d.meters;
  stage++;
}

function defaultLocation(email) {
  if (email === 'bdavis64@icloud.com' || email === 'lynnoldershaw123@gmail.com') {
    // Toronto
    return {
      latitude: 43.68360974888432,
      longitude: -79.39513531615856,
    };
  }
    // SF
  return {
    latitude: 37.78339535705197,
    longitude: -122.43428461143354,
  };
}
let initialCoords = defaultLocation($auth.user.email);
let coords = initialCoords;
let requestedLocation = false;
$: {
  if (!requestedLocation) {
    // Don't request permission to use location until the user has interacted
    // with the form.
    if (stage !== STAGE_PICK_TYPE && !isHomeCooking) {
      getLocation()
        .then(loc => {
          // Only update coords if user has not moven away from fallback before
          // receiving actual location.
          if (coords == initialCoords) {
            coords = loc
          }
        })
        .catch(err => console.error(err));
      requestedLocation = true;
    }
  }
};

function initialParticipants(email) {
  if (email === 'aidan.oldershaw@gmail.com' || email === 'rebeccagwakefield@gmail.com') {
    return ['aidan.oldershaw@gmail.com', 'rebeccagwakefield@gmail.com'];
  }
  if (email === 'bdavis64@icloud.com' || email === 'lynnoldershaw123@gmail.com') {
    return ['bdavis64@icloud.com', 'lynnoldershaw123@gmail.com'];
  }
  return [email];
}

let participants = initialParticipants($auth.user.email);
let activeParticipants = [...participants];
let currentParticipant = '';
function addParticipant() {
  if (currentParticipant === '') return;
  currentParticipant = currentParticipant.toLowerCase();
  participants = participants.concat(currentParticipant);
  activeParticipants = activeParticipants.concat(currentParticipant);
  currentParticipant = '';
}

let submitPending = false;
let submitError;
function handleSubmit() {
  const owner = $auth.user.email;

  const params = {type: typeObj.id};
  if (!isHomeCooking) {
    params.restrictToOpenNow = restrictToOpenNow;
    params.keyword = keyword;
    params.distance = distance;
    params.coords = coords;
  }
  const session = {
    type: typeObj.id,
    params: params,
    participants: activeParticipants,
    owner,
  };
  submitPending = true;
  if (!isHomeCooking) {
    loadPlaces(session)
      .then((places) => {
        console.log(places);
        session.placeIds = places.map(p => p.place_id);
        return session;
      })
      .then((session) => saveSession(session))
      .then((id) => navigate(`/sessions/${id}`))
      .catch((error) => submitError = error)
      .finally(() => submitPending = false);
  } else {
    saveSession(session)
      .then((id) => navigate(`/sessions/${id}`))
      .catch((error) => submitError = error)
      .finally(() => submitPending = false);
  }
}

const NUM_RECENT_SESSIONS = 3;
let recentSessionsPromise = getRecentSessions($auth.user.email, NUM_RECENT_SESSIONS);
</script>

<form on:submit|preventDefault={() => handleSubmit()} onkeydown="return event.key != 'Enter';">
  <Stage thisStage={STAGE_PICK_TYPE} bind:stage>
    <h2>What are you looking for?</h2>
    <div class="options">
      {#each AVAILABLE_TYPES as type}
        <span on:click|stopPropagation={() => selectType(type)}
            class:active={(typeObj || {}).id === type.id}>{type.name}</span>
      {/each}
    </div>
  </Stage>

  {#if !isHomeCooking}
  <Stage thisStage={STAGE_PICK_PARAMS} bind:stage>
    <h2>What type of {typeObj.name} are you looking for?</h2>
    <div class="form-element">
      <label for="open-now">Restrict to open now?</label>
      <input id="open-now" type="checkbox" bind:checked={restrictToOpenNow}>
    </div>
    <div class="form-element">
      <label for="keyword">Keyword (optional)</label>
      <input id="keyword" bind:value={keyword} placeholder="e.g. {typeObj.exampleKeyword}">
    </div>
    <button class="button" type="button" on:click|stopPropagation={handleContinue} transition:fade>Continue</button>
  </Stage>

  <Stage thisStage={STAGE_PICK_DISTANCE} bind:stage>
    <h2>How far are you willing to go?</h2>
    <div class="options">
      {#each AVAILABLE_DISTANCES as distance_}
        <span on:click|stopPropagation={() => selectDistance(distance_)}
              class:active={distance === distance_.meters}>{distance_.name}</span>
      {/each}
    </div>
  </Stage>

  <Stage thisStage={STAGE_PICK_LOCATION} bind:stage>
    <h2>Where are you looking?</h2>
    <LocationPicker bind:coords radius={distance}/>
    <button class="button" type="button" on:click|stopPropagation={handleContinue} transition:fade>Continue</button>
  </Stage>
  {/if}

  <Stage thisStage={STAGE_PICK_PARTICIPANTS} bind:stage>
    <h2>Who else will be swiping?</h2>
    <div class="participants">
      {#each participants as participant}
        <label>
          <input type="checkbox" bind:group={activeParticipants} value={participant} disabled={participant === $auth.user.email}>
          {participant}
        </label>
      {/each}
    </div>

    <div class="input-and-button">
      <input bind:value={currentParticipant} placeholder="Enter an email">
      <button disabled={!currentParticipant} type="button" on:click={addParticipant}>Add</button>
    </div>

    <button class="button" type="submit" disabled={submitPending} transition:fade>Start Swiping</button>
    {#if submitError}
      <p class="error">Error: {submitError}</p>
    {/if}
  </Stage>

  <!-- Recent sessions -->
  {#if onFirstStage}
    {#await recentSessionsPromise then recentSessions}
      {#if recentSessions.length > 0}
        <div class="recent-sessions" in:fade={{delay: 500}}>
          <h3>Recent Sessions</h3>
          {#each recentSessions as session}
            <SessionCard {session} />
          {/each}
        </div>
      {/if}
    {/await}
  {/if}
</form>

<style>
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
  }

  .options {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    width: 100%;
  }

  .options > span {
    cursor: pointer;
    padding: 16px;
    margin: 4px;
    background-color: #ddd;
    border-radius: 4px;
    font-weight: bold;
    color: #333;
  }

  .options > span.active {
    background-color: #777;
    color: #fff;
  }

  .form-element {
    display: flex;
    align-items: center;
    max-width: 400px;
  }

  .form-element:not(:last-child) {
    margin-bottom: 1em;
  }

  .form-element label {
    flex-basis: 200px;
  }

  .form-element input {
    margin: 0;
  }

  .button {
    border-radius: 4px;
    padding: 8px 16px;
    background-color: #000033;
    color: #fff;
    cursor: pointer;
    margin-top: 8px;
  }

  .button[disabled] {
    background-color: #2d2d38;
    cursor: default;
  }

  .recent-sessions {
    position: absolute;
    bottom: 16px;
    max-width: 480px;
    min-width: 300px;
  }
  .recent-sessions h3 {
    text-align: center;
  }

  .input-and-button {
    display: flex;
    width: 100%;
  }
  .input-and-button input {
    flex: 1;
  }

  .participants {
    text-align: left;
  }
</style>

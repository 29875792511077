import { writable, derived, get } from "svelte/store";

import {
  loadSession,
  loadRestaurants,
  loadRestaurantByMeal,
  listenToSessionResponses,
  RESPONSE_YES,
  RESPONSE_NO,
} from "./db.js";
import { approxDistanceKm } from "./location.js";
import { loadSides } from "./home_cooking.js";
import { isHomeCookingType } from "./utils.js";

// Load responses (i.e. swipes yes or no) for every user in the session. Keyed
// by item (e.g. restaurant) id, then email - value is response (good or bad).
// A callback is invoked for every new snapshot consisting of the updated state
// and a list of item ids with modified responses.
//
// Returns an unsubscribe function that must be called when we should stop
// listening to new updates.
function loadSessionResponses(id, callback) {
  const itemResponses = {};
  return listenToSessionResponses(id, (snapshot) => {
    const modifiedIds = [];
    snapshot.docChanges().forEach((change) => {
      const r = change.doc.data();
      modifiedIds.push(r.itemId);
      if (change.type === "added" || change.type === "modified") {
        if (!itemResponses[r.itemId]) {
          itemResponses[r.itemId] = {};
        }
        itemResponses[r.itemId][r.email] = r.response;
      }
      if (change.type === "removed") {
        if (itemResponses[r.itemId]) {
          delete itemResponses[r.itemId][r.email];
        }
      }
    });
    callback(itemResponses, modifiedIds);
  });
}

export async function loadSessionState(id, email) {
  // Load static data.
  let session = await loadSession(id);
  console.log(session);
  let itemIds;
  let homeCookingData;
  if (isHomeCookingType(session.params.type)) {
    homeCookingData = await loadSides();
    console.log(homeCookingData);
    // Names as ids is a bit sketchy - hopefully no duplicates.
    itemIds = shuffle(homeCookingData.map((d) => d.name));
  } else {
    itemIds = shuffle(session.placeIds);
  }

  function indexOfItem(itemId) {
    for (const [i, id] of itemIds.entries()) {
      if (id === itemId) return i;
    }
    return itemIds.length;
  }

  // Index into items of next item to return.
  let index = 0;
  // Index at which to insert the next prioritized item.
  let nextPrioritizeIndex = 0;

  // Subscribe to Firestore for response updates.
  let itemResponses = {};
  let matches = writable([]);
  // Subscribe to firestore, but wait for the initial set of data to come in.
  const unsubscribe = await new Promise((resolve) => {
    let unsubscribe;
    unsubscribe = loadSessionResponses(id, (responses, updatedIds) => {
      itemResponses = responses;
      console.log("got", itemResponses, updatedIds);
      for (const updatedId of updatedIds) {
        // Update priority array.
        if (shouldPrioritize(updatedId)) {
          console.log("Prioritizing", updatedId);
          const prioritizedItemIndex = indexOfItem(updatedId);
          if (
            prioritizedItemIndex == null ||
            prioritizedItemIndex >= itemIds.length ||
            prioritizedItemIndex <= index
          ) {
            // Should not happen.
            continue;
          }
          const prioritizedItem = itemIds[prioritizedItemIndex];
          const deprioritizedItem = itemIds[nextPrioritizeIndex];
          itemIds[nextPrioritizeIndex] = prioritizedItem;
          itemIds[prioritizedItemIndex] = deprioritizedItem;
          nextPrioritizeIndex++;
        }
        // Update matches store.
        if (hasMatch(updatedId)) {
          console.log("Matched", updatedId);
          matches.update((matchItems) => {
            if (matchItems.every((id) => id !== updatedId)) {
              const matchIndex = indexOfItem(updatedId);
              if (matchIndex < itemIds.length) {
                matchItems.push(itemIds[matchIndex]);
              }
            }
            return matchItems;
          });
        }
      }
      resolve(unsubscribe);
    });
  });

  // Should look at items that are not yet reviewed by self and have not been
  // responded "no" to by another user.
  function shouldReview(itemId) {
    if (!itemResponses[itemId]) return true;
    if (itemResponses[itemId][email]) return false;
    for (const otherEmail in itemResponses[itemId]) {
      if (itemResponses[itemId][otherEmail] === RESPONSE_NO) {
        return false;
      }
    }
    return true;
  }

  // Should prioritize items that are liked by other users, but not self.
  function shouldPrioritize(itemId) {
    if (!itemResponses[itemId]) return false;
    if (itemResponses[itemId][email]) return false;
    for (const otherEmail in itemResponses[itemId]) {
      if (itemResponses[itemId][otherEmail] === RESPONSE_YES) {
        return true;
      }
    }
    return false;
  }

  // Match means all participant liked an item.
  function hasMatch(itemId) {
    if (!itemResponses[itemId]) return false;
    return (
      Object.keys(itemResponses[itemId]).length ===
        session.participants.length &&
      Object.values(itemResponses[itemId]).every(
        (response) => response === RESPONSE_YES
      )
    );
  }

  const placeCache = {};

  return {
    session,
    unsubscribe,
    matches,
    getNextItem() {
      while (index < itemIds.length) {
        const curIndex = index;
        index++;
        if (index >= nextPrioritizeIndex) {
          nextPrioritizeIndex = index;
        }
        if (shouldReview(itemIds[curIndex])) {
          return itemIds[curIndex];
        }
      }
      return null;
    },
  };
}

function shuffle(array) {
  let currentIndex = array.length;

  while (currentIndex != 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    let tmp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = tmp;
  }

  return array;
}

<script>
import { onMount, createEventDispatcher } from 'svelte';
import { fade } from "svelte/transition";

import { approxDistanceKm } from "./location.js";
import { addressLink, streetAddress } from './restaurant_utils.js';
import { lookupPlace, isMapsApiReady, canonicalizePlace } from './maps.js';

import Carousel from 'svelte-carousel'
import Hammer from 'hammerjs';

export let placeId;
export let location;
export let active;

let lookupPlacePromise;

// TODO: awful hack
let restaurant = canonicalizePlace(placeId, {});
$: {
  if ($isMapsApiReady && lookupPlacePromise == null) {
    lookupPlacePromise = lookupPlace(placeId).then((place) => {
      restaurant = place;
    });
  }
}

export let swipeLeftId = null;
export let swipeRightId = null;

let transform = '';
$: {
  const moveOutWidth = document.body.clientWidth * 1.5;
  if (placeId === swipeLeftId) {
    transform = `translate(-${moveOutWidth}px, -100px) rotate(30deg)`;
  }
  if (placeId === swipeRightId) {
    transform = `translate(${moveOutWidth}px, -100px) rotate(-30deg)`;
  }
}

const dispatch = createEventDispatcher();

const X_THRESHOLD_ACCEPT_PX = 80;
let element;
let moving = false;
let swipingImage = false;
let deltaX = 0;
let deltaY = 0;
onMount(() => {
  const hammer = new Hammer(element);
  hammer.on('pan', (event) => {
    if (!active) return;
    // Prevent panning when swiping images.
    if (
      (event.target.nodeName === "IMG" &&
        event.target.classList.contains("swipeable")) ||
      event.target.classList.contains("carousel-button")
    ) {
      swipingImage = true;
      return;
    }
    if (swipingImage) return;
    moving = true;
    if (event.deltaX === 0) return;
    if (event.center.x === 0 && event.center.y === 0) return;
    deltaX = event.deltaX;
    deltaY = event.deltaY;

    const xMulti = event.deltaX * 0.03;
    const yMulti = event.deltaY / 80;
    const rotate = xMulti * yMulti;

    transform = `translate(${deltaX}px, ${deltaY}px) rotate(${rotate}deg)`;
  });

  hammer.on('panend', (event) => {
    if (!active) return;
    if (swipingImage) {
      swipingImage = false;
      return;
    }
    moving = false;

    const moveOutWidth = document.body.clientWidth;
    const keep = Math.abs(event.deltaX) < X_THRESHOLD_ACCEPT_PX;

    if (keep) {
      transform = '';
      deltaX = 0;
      deltaY = 0;
    } else {
      deltaX = event.deltaX;
      deltaY = event.deltaY;
      const velocityX = Math.max(event.velocityX, 0.5);
      const velocityY = event.velocityY;

      const endX = Math.max(Math.abs(velocityX) * moveOutWidth, moveOutWidth);
      const toX = event.deltaX > 0 ? endX : -endX;
      const endY = Math.abs(velocityY) * moveOutWidth;
      const toY = event.deltaY > 0 ? endY : -endY;
      const xMulti = event.deltaX * 0.03;
      const yMulti = event.deltaY / 80;
      const rotate = xMulti * yMulti;

      transform = `translate(${toX}px, ${toY + event.deltaY}px) rotate(${rotate}deg)`;
      if (event.deltaX > 0) {
        dispatch('swiperight');
      } else {
        dispatch('swipeleft');
      }
    }
  })
});

$: roundedDistanceKm = Math.round(approxDistanceKm(location, restaurant) * 10) / 10;

const imageUrlCache = new WeakMap();
function getImageUrl(image) {
  if (imageUrlCache.has(image)) return imageUrlCache.get(image);
  console.log('fetching', image);
  const imageUrl = image.getUrl();
  imageUrlCache.set(image, imageUrl);
  return imageUrl;
}
</script>

<div class="card" bind:this={element} class:moving class:active style="transform: {transform}">
<div class="card-content">
  {#if restaurant.images.length > 0}
  <Carousel
    particlesToShow={1}
    particlesToScroll={1}
    arrows={restaurant.images.length > 1}
    dots={restaurant.images.length > 1}
    swiping={restaurant.images.length > 1}
    let:loaded
    let:showPrevPage
    let:showNextPage>
    <div slot="prev" on:click={showPrevPage} class="carousel-button carousel-button-prev">
      <i></i>
    </div>
    {#each restaurant.images as image, index}
      <div class="image-container">
        {#if loaded.includes(index)}
          <img src={getImageUrl(image)} alt={getImageUrl(image)} class:swipeable={restaurant.images.length > 1}>
        {/if}
      </div>
    {/each}
    <div slot="next" on:click={showNextPage} class="carousel-button carousel-button-next">
      <i></i>
    </div>
  </Carousel>
  {/if}
  <h2 class="header">
    {restaurant.name}
    <div class="details">
      {restaurant.priceLevel}
    </div>
  </h2>
  <div class="rating">
    {restaurant.rating} ★ ({restaurant.numReviews} reviews)
  </div>
  <div class="location">
    <a class="address" href={addressLink(restaurant.address)}>{streetAddress(restaurant.address)}</a>
    {#if restaurant.neighbourhood}
      <div class="neighbourhood">{restaurant.neighbourhood}</div>
    {/if}
    <div class="distance">~{roundedDistanceKm} km away</div>
  </div>
  {#if restaurant.website}
    <a class="website" href={restaurant.website}>Website</a>
  {/if}
</div>

{#if deltaX <= -X_THRESHOLD_ACCEPT_PX}
  <div class="overlay-text nope" transition:fade={{duration:150}}>Nope</div>
{:else if deltaX >= X_THRESHOLD_ACCEPT_PX}
  <div class="overlay-text like" transition:fade={{duration:150}}>Like</div>
{/if}
</div>

<style>
  .card {
    position: absolute;
    will-change: transform;
    transition: transform 300ms ease-in-out;
    max-width: 480px;
    margin-left: auto; 
    margin-right: auto;
    left: 8px;
    right: 8px;
    color: #333;
    text-align: left;
  }

  .card.active {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .card.active.moving {
    transition: none;
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .card-content {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 16px 32px;
    border-radius: 8px;
    height: 430px;
    overflow: hidden;
  }

  @media only screen and (min-height: 650px) {
    .card-content {
      height: 450px;
    }
  }

  img {
    height: 240px;
    max-width: 480px;
    max-height: 480px;
    object-fit: cover;
    pointer-events: all;
  }

  .header {
    margin: 8px 0;
  }
  .header .details {
    font-size: 0.6em;
    color: #666;
  }

  .rating {
    margin-bottom: 8px;
  }

  .location {
    margin-bottom: 8px;
  }

  .carousel-button {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    transition: background-color 150ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .carousel-button i {
    border: solid white;
    border-width: 0 5px 5px 0;
    padding: 5px;
    position: relative;
  }

  .carousel-button-prev {
    left: 0;
    padding: 0 30px 0 10px;
    background: linear-gradient(90deg, rgba(0,0,0,0.3), rgba(0,0,0,0));
  }
  .carousel-button-prev i {
    transform: rotate(135deg);
    right: -4px;
  }

  .carousel-button-next {
    right: 0;
    padding: 0 10px 0 30px;
    background: linear-gradient(270deg, rgba(0,0,0,0.3), rgba(0,0,0,0));
  }
  .carousel-button-next i {
    transform: rotate(-45deg);
    left: -4px;
  }

  .overlay-text {
    position: absolute;
    border-radius: 4px;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 3rem;
    box-shadow: 0 0 8px rgb(0 0 0 / 50%);
    text-shadow: 0 0 8px rgb(0 0 0 / 50%);
    text-transform: uppercase;
  }

  .overlay-text.nope {
    top: 50px;
    right: 40px;
    color: #f53434;
    border: 4px solid #f53434;
    transform: rotate(30deg);
  }
  .overlay-text.like {
    top: 44px;
    left: 44px;
    color: #42a941;
    border: 4px solid #42a941;
    transform: rotate(-30deg);
  }

  .image-container img {
    width: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
  }
</style>
